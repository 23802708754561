@import url("https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@400;500;700&display=swap");
body {
	margin: 0;
	line-height: normal;
	/* background-color: var(--color-gray-200); */
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root {
	/* fonts */
	/* --default-font: "Helvetica Neue"; */

	/* font sizes */
	--font-size-xs: 12px;
	--font-size-sm: 14px;
	--font-size-mini-4: 14.4px;
	--font-size-base: 16px;
	--helvetica-neue-18px-regular-size: 18px;
	--helvetica-neue-32px-medium-size: 32px;

	/* Colors */
	--color-gray-100: #070d1a;
	--color-gray-200: #010817;
	--color-gainsboro: rgba(225, 227, 234, 0);
	--color-cornflowerblue: #92a5ed;
	--color-lightsteelblue-100: #c2cfff;
	--color-lightsteelblue-200: #b2c0f4;
	--color-lavender: #dee5ff;
	--white-50: #fff;
	--caution-600: #ee6c20;
	--color-chocolate-100: rgba(238, 108, 32, 0.25);
	--color-royalblue-100: #2073ee;
	--color-royalblue-200: rgba(58, 93, 224, 0.11);
	--color-dodgerblue-100: #708eff;
	--color-dodgerblue-200: rgba(112, 142, 255, 0.5);
	--color-mediumslateblue: #345be9;
	--color-steelblue-100: #6c79aa;
	--color-steelblue-200: #546894;
	--color-lightsalmon: #ffb082;
	--secondary-500: #f6ac19;
	--disable-color: #071739;

	/* Gaps */
	--gap-xs: 12px;
	--gap-11xs: 2px;
	--gap-5xl: 24px;
	--gap-7xs: 6px;
	--gap-6xs-2: 6.2px;
	--gap-9xs: 4px;

	/* Paddings */
	--padding-xs: 12px;
	--padding-11xs: 2px;
	--padding-5xl: 24px;
	--padding-6xs-2: 6.2px;
	--padding-15xl: 34px;
	--padding-7xs: 6px;
	--padding-9xs: 4px;
	--padding-sm: 14px;

	/* Border radiuses */
	--br-3xs: 10px;
	--br-126xl-6: 145.6px;
	--br-53945xl-3: 53964.3px;
	--br-80xl: 99px;
	--br-5xs: 8px;

	/* side drawer */
	--side-drawer-width: 220px;

	/* Top Navigation */
	--top-nav-width: 80px;
}

.mainpage-background {
	padding: 0 9px;
	display: flex;
	height: 100vh;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	flex: 1;
	/* background-color: var(--color-gray-100); */
	padding-bottom: 34px;
}

.main-card {
	width: 100%;
	height: 100%;
}

@keyframes shake {
	0%, 100% {
		transform: translateX(0);
	  }
	  10%, 30%, 50%, 70%, 90% {
		transform: translateX(-5px);
	  }
	  20%, 40%, 60%, 80% {
		transform: translateX(5px);
	}
  }
  
.tab-error {
	color: red !important;
	animation: shake 1s ease-in-out;
  }

.gradient-border {
	border: 1px solid transparent;
	border-radius: 17px !important;
	background-image: linear-gradient(#F7F9FB, #F7F9FB), 
						linear-gradient(to right, #5776E7, #FFAC21, #5776E7) !important;
	background-origin: border-box !important;
	background-clip: content-box, border-box !important;
  }

.mainpage-container {
	display: flex;
	padding: 24px 24px 0px 0px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	width: 100%;
	flex: 1;
}

.section-tag {
	display: flex;
	flex-direction: column;
	row-gap: 12px;
	width: 100%;
}

.input-wrapper {
	gap: var(--gap-xs);
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-start;
	padding: var(--padding-5xl) 0px 0px;
	box-sizing: border-box;
	text-align: center;
	font-size: var(--font-size-sm);
	color: var(--color-cornflowerblue);
	font-family: var(--default-font);
	flex-wrap: wrap;
}

.overview-status-card-wrapper {
	width: 98%;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	box-sizing: border-box;
	gap: var(--gap-xs);
	text-align: left;
	font-size: var(--font-size-base);
	color: var(--white-50);
	font-family: var(--default-font);
	flex-wrap: wrap;
}

.home-main-container {
	display: flex;
	padding: 24px px 12px 24px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	align-self: stretch;
}

.requiredstar {
	color: red !important;
}

.icon {
    cursor: pointer;
    display: flex;
    p {
        margin-left: 4px;
        font-size: 14px;
        font-weight: 700;
        color: #999999;
    }
}
.viewMore {
	font-size: 14px;
    display: grid;
    place-items: center;
    margin: auto;
    text-align: center;
    min-height: 9.875rem;
    font-weight: 500;
    a {
        cursor: pointer;
        text-decoration: underline;
		color:#95A4FC;
    }
}